
import { defineComponent, ref, watchEffect } from "vue";
import { cloneDeep } from "lodash";
import { useGetAll, useRoute } from "@/helpers";
import { PHASELOG_FRAGMENT } from "@/graphql";
import Loading from "@/components/loading/index.vue";
import Empty from "@/components/empty/index.vue";
import HistoryItem from "../components/history-item.vue";

export default defineComponent({
  components: { Loading, Empty, HistoryItem },
  setup() {
    const logs = ref<any[]>([]);
    const { query, back } = useRoute();
    const { data, loading } = useGetAll(
      "FarmingDiaryPhaseLog",
      PHASELOG_FRAGMENT
      //   { filter: String(query.id) }
    );
    watchEffect(() => {
      if (data.value) {
        logs.value = [
          {
            id: "5f4c7cb5d4010e35686bd718",
            name: "testc",
            attributes: [
              {
                display: "Bón phân",
                type: "string",
                key: "keytest",
                value: "Theo diện tích"
              },
              {
                display: "Độ PH",
                key: "ph",
                type: "number",
                value: 28
              },
              {
                display: "Sản phẩm",
                key: "sp",
                type: "select",
                value: ["Tôm", "Cá", "Lúa", "Cà chua"]
              },
              {
                display: "Hình ảnh",
                key: "image",
                type: "image",
                value: [
                  "https://i.imgur.com/dnFaeQ9.png",
                  "https://i.imgur.com/dnFaeQ9.png",
                  "https://i.imgur.com/dnFaeQ9.png",
                  "https://i.imgur.com/dnFaeQ9.png"
                ]
              }
            ],
            time: "2020-08-31T04:29:42.274Z",
            phaseId: null,
            staffId: null,
            createdAt: "2020-08-31T04:29:42.274Z",
            updatedAt: "2020-08-31T04:29:42.274Z"
          },
          {
            id: "5f4c7cfcd4010e35686bd719",
            name: "testc",
            attributes: [
              {
                display: "Bón phân",
                type: "string",
                key: "keytest",
                value: "Theo diện tích"
              }
            ],
            time: "2020-08-31T04:29:42.274Z",
            phaseId: null,
            staffId: null,
            createdAt: "2020-08-31T04:30:53.263Z",
            updatedAt: "2020-08-31T04:30:53.263Z"
          },
          {
            id: "5f4c7d45bd7ca3573444da0c",
            name: "testc",
            attributes: [
              {
                display: "Độ PH",
                key: "ph",
                type: "number",
                value: 28
              }
            ],
            time: "2020-08-31T04:29:42.274Z",
            phaseId: null,
            staffId: null,
            createdAt: "2020-08-31T04:32:06.043Z",
            updatedAt: "2020-08-31T04:32:06.043Z"
          }
        ];
      }
    });
    return { logs, loading, back };
  }
});
