
import { defineComponent } from "vue";
import { format } from "date-fns";
import { Tag3, Avatar3 } from "@/uikit";
import { ERoutesName } from "@/router";
import { useRoute } from "@/helpers";

export default defineComponent({
  props: ["history"],
  components: { Tag3, Avatar3 },
  setup() {
    const { push } = useRoute();
    const handleViewFull = (link: string) =>
      push({ name: ERoutesName.VIEW_IMAGE, query: { link } });
    return { format, handleViewFull };
  }
});
